import React from 'react';
import Map from './components/Map/Map'
import 'antd/dist/antd.css';
import './App.css';

function App() {
  return (
    <Map />
  );
}

export default App;
