import React, { Component } from 'react'
import ReactMapboxGl, { ZoomControl, Layer, Source, Popup } from 'react-mapbox-gl'
import axios from 'axios'
import { Row, Col } from 'antd'
import './Map.css'
const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoiZG1jY2FyZXkiLCJhIjoiRl9FV3ZXNCJ9.l1rdsm-F9Vwzcimtf1qMHg',
  scrollZoom: false,
  minZoom: 3
})


const locations = [{
  coords: [-80.41, 25.004],
  zoom: [9],
  bearing: [138.70673828625604],
},{
  coords: [-81.896, 26.065],
  zoom: [9],
  bearing: [-115.450],
},{
  coords: [-79.425, 30.676],
  zoom: [7],
  bearing: [104.158],
},{
  coords: [-117.213, 32.628],
  zoom: [11],
  bearing: [-123.60],
}
]

class CCMap extends Component {
  constructor(props) {
    super(props)
    const item = locations[Math.floor(Math.random()*locations.length)]
    this.state = {
      zoom: item.zoom,
      center: item.coords,
      pitch: [0],
      bearing: item.bearing,
      rotation: [0],
      popupContent: '',
      showPopup: false,
      showLegend: false,
      showIntro: true,
      showLayers: false,
      dragPan: false,
      popupCoords: [-0.13235092163085938,51.518250335096376],
      projects: {
        type: "FeatureCollection",
        features: []
      },
      stories: {
        type: "FeatureCollection",
        features: []
      },
      projectLines: {
        type: "FeatureCollection",
        features: []
      },
      image: item.image,
      loading: true,
      visible: false
    }
  }

  componentDidMount() {
    const projectsJSON = {
      type: "FeatureCollection",
      features: []
    }
    const projectLinesJSON = {
      type: "FeatureCollection",
      features: []
    }
    const storiesJSON = {
      type: "FeatureCollection",
      features: []
    }
    const storyLinesJSON = {
      type: "FeatureCollection",
      features: []
    }
    axios.all([
      axios.get('https://docs.google.com/spreadsheets/d/1YJuKJQx9kja5d7q-Tel-QWwYWWTNQACMYpHZqYV6ktM/gviz/tq?tqx=out:json&sheet=Projects'),
      axios.get('https://docs.google.com/spreadsheets/d/1YJuKJQx9kja5d7q-Tel-QWwYWWTNQACMYpHZqYV6ktM/gviz/tq?tqx=out:json&sheet=Stories'),
     ]).then((res) => {
       
      const projects = JSON.parse(res[0].data.substr(47).slice(0, -2))
      const stories = JSON.parse(res[1].data.substr(47).slice(0, -2))

      projects.table.rows.forEach((item, index) => {
        
        if (item.c[4] && item.c[3] && item.c[2]) {
          projectsJSON.features.push({
            type: "Feature",
            geometry: {
              coordinates: [item.c[3].v, item.c[2].v],
              type: "Point"
            },
            properties: {
              longitude: item.c[3].v,
              latitude: item.c[2].v,
              title: item.c[0].v,
              partner: item.c[1].v,
              link: item.c[4].v,
              image: item.c[5].v
            }
          })
        }
        if (projects.table.rows[index+1]) {
          projectLinesJSON.features.push({
            type: "Feature",
            geometry: {
              coordinates: [
                [item.c[3]?.v, item.c[2]?.v],
                [projects.table.rows[index+1].c[3]?.v, projects.table.rows[index+1].c[2]?.v],
              ],
              type: "LineString"
            }
          })
        }
      })

      stories.table.rows.forEach((item, index) => {
        storiesJSON.features.push({
          type: "Feature",
          geometry: {
            coordinates: [item.c[3].v, item.c[2].v],
            type: "Point"
          },
          properties: {
            longitude: item.c[3].v,
            latitude: item.c[2].v,
            title: item.c[0].v,
            image: item.c[5].v,
            partner: item.c[1].v,
            link: item.c[4].v,
          }
        })
        if (item.c[1].v) {
          projects.table.rows.forEach((row, index) => {
            if (row.c[1]?.v === item.c[1]?.v) {
              storyLinesJSON.features.push({
                type: "Feature",
                geometry: {
                  coordinates: [
                    [item.c[3]?.v, item.c[2]?.v],
                    [row.c[3]?.v, row.c[2]?.v],
                  ],
                  type: "LineString"
                }
              })
           }
         })
        }
      })

      this.setState({
        projects: projectsJSON,
        stories: storiesJSON,
        storyLines: storyLinesJSON,
        projectLines: projectLinesJSON
      })
    })
  }


  mapLoaded(map) {
    const item = locations[Math.floor(Math.random()*locations.length)]
    this.setState({
      map: map,
      loading: false
    })
  }

  handleStoryClick(e) {
    const features = this.state.map.queryRenderedFeatures(e.point)
    const feature = features[0]
    if (feature &&
      feature.layer.id === "layer-stories" &&
      feature.properties.image !== '') {
        this.state.map.getCanvas().style.cursor = 'pointer';
        this.setState({
          image: this.handleImageFade(feature.properties.image),
          popupCoords: [feature.properties.longitude, feature.properties.latitude],
          showPopup: true,
          popupContent: feature.properties
        })
    }
  }


  handleProjectClick(e) {
    const features = this.state.map.queryRenderedFeatures(e.point)
    const feature = features[0]
    if (feature &&
      feature.layer.id === "layer-projects") {
        this.state.map.getCanvas().style.cursor = 'pointer';
        this.setState({
          image: this.handleImageFade(feature.properties.image),
          popupCoords: [feature.properties.longitude, feature.properties.latitude],
          showPopup: true,
          popupContent: feature.properties
        })
    }
  }

  handleImageFade(image) {
    if (this.state.image === undefined) {
      return image
    } else if (image === this.state.image) {
      return image
    } else {
      this.setState({
        visible: false
      })
      setTimeout(() => {
        this.setState({
          image: image,
          visible: true
        })
      }, 1000)
    }
  }

  render() {
    return(
      <>
      { this.state.loading &&
        <div className="loading"></div>
      }
      <div className="map">
        <div
          className={this.state.visible ? 'background-image fadeIn' : 'background-image fadeOut' }
          style={{
            background: `url(${this.state.image}) no-repeat center center`,
          }}
        >
        </div>
        <Map
        //eslint-disable-next-line
        style="mapbox://styles/dmccarey/ck5vrdnhi5f2r1is7z8p6gq5m"
        center={ this.state.center }
        zoom={ this.state.zoom }
        pitch={ this.state.pitch }
        bearing={ this.state.bearing }
        rotation={ this.state.rotation }
        onClick={ (map) =>{
          console.log(
            map.getCenter(),
            map.getBearing()
           )
        }}
        movingMethod="flyTo"
        flyToOptions={{ speed: 0.7, curve: 1.5}}
        onStyleLoad={ this.mapLoaded.bind(this) }
        containerStyle={{
          height: '100%',
          width: '100%'
        }}
        >
        <Source
          id="source-projects"
          geoJsonSource={{
            type: "geojson",
            data: this.state.projects
          }}
        />
        <Source
          id="source-project-lines"
          geoJsonSource={{
            type: "geojson",
            data: this.state.projectLines
          }}
        />
        <Source
          id="source-stories"
          geoJsonSource={{
            type: "geojson",
            data: this.state.stories
          }}
        />
        <Source
          id="source-story-lines"
          geoJsonSource={{
            type: "geojson",
            data: this.state.storyLines
          }}
        />
        { this.state.showLayers && this.state.storyLines &&
        <Layer
          id="layer-story-lines"
          sourceId="source-story-lines"
          type="line"
          paint={{
            "line-width": 2,
            "line-opacity": 1,
            "line-color": "#FC8404"
          }}
        />
        }
        { this.state.showLayers && this.state.projectLines &&
        <Layer
          id="layer-project-lines"
          sourceId="source-project-lines"
          type="line"
          paint={{
            "line-width": 2,
            "line-opacity": 1,
            "line-dasharray": [1, 2],
            "line-color": "#FC8404"
          }}
        />
        }
        { this.state.showLayers &&
        <Layer
          id="layer-project-labels"
          sourceId="source-projects"
          type="symbol"
          layout={{
            "text-field": "{partner}",
            "text-size": 14,
            "text-offset": [0, 2]
          }}
          paint={{
            "text-color": "#11414C",
            "text-halo-color": "rgba(255,255,255, 0.3)",
            "text-halo-width": 2,
            "text-halo-blur": 2,
          }}
        />
        }
        { this.state.showLayers &&
        <Layer
          id="layer-stories"
          sourceId="source-stories"
          onMouseEnter={ this.handleStoryClick.bind(this) }
          type="circle"
          paint={{
            "circle-radius": 10,
            "circle-color": "#FC8404",
            "circle-opacity": 0.7,
            "circle-pitch-alignment": "map"
          }}
        />
        }
        { this.state.showLayers &&
        <Layer
          id="layer-projects"
          sourceId="source-projects"
          onMouseEnter={ this.handleProjectClick.bind(this) }
          type="circle"
          paint={{
            "circle-radius": 12,
            "circle-color": "#FC8404",
            "circle-opacity": 0.7,
            "circle-pitch-alignment": "map"
          }}
        />
        }
        { this.state.showLayers &&
        <Layer
          id="layer-projects-points"
          sourceId="source-projects"
          onMouseEnter={ this.handleProjectClick.bind(this) }
          type="circle"
          paint={{
            "circle-radius": 3,
            "circle-blur": 1,
            "circle-color": "#ffffff",
            "circle-pitch-alignment": "map"
          }}
        />
        }
        { this.state.showPopup &&
          <Popup
            coordinates={ this.state.popupCoords }
          >
            <button
             className="btn-close-popup"
             onClick={(e) => {
               this.setState({
                 showPopup: false
               })
             }}
            >
            x
            </button>
           { this.state.popupContent.link ?
             <p><a className="story-title" target="blank" href={ this.state.popupContent.link }>{ this.state.popupContent.title }</a><br/>
             <span className="partner">{ this.state.popupContent.partner }</span>
             </p>
            :
            <p>Coming soon</p>
            }
          </Popup>
        }
        { this.state.showLayers &&
        <ZoomControl
          style={{
            right: '50px',
            top: '50px'
          }}
        />
       }
       { this.state.showIntro &&
       <div className="intro">
        <p>Connected Coastlines is a nationwide climate reporting initiative in coastal states hosted by the Pulitzer Center.</p>
        <a
          className="btn-explore"
          onClick={ (e) => {
            e.preventDefault()
            this.setState({
              zoom: [2.3],
              center: [-100, 37],
              pitch: [0],
              showLegend: true,
              showLayers: true,
              showIntro: false,
              bearing: [0],
            })
          }}
        >
         Explore
        </a>
        <a className="btn-join" target="blank" href="https://pulitzercenter.org/connected-coastlines-initiative">Join our network &raquo;</a>
       </div>
      }
       </Map>
      { this.state.showLegend &&
       <div className="legend">
        <span className="partner"></span>&nbsp;&nbsp; Partner &nbsp;&nbsp;
        <span className="story"></span>&nbsp;&nbsp; Story
       </div>
     }
     <a className="title" target="blank" href="/">Connected Coastlines</a>
     <a className="logo" target="blank" href="https://pulitzercenter.org/connected-coastlines-initiative">Pulitzer Center</a>
     </div>

     <div className="partners">
      <div className="content">
        <h2>Partners</h2>
        <Row>
        <Col sm={8}>
          <a href="https://www.nytimes.com/" target="blank" className="partner new-york-times">
            The New York Times
          </a>
        </Col>
          <Col sm={8}>
            <a href="https://www.chicagotribune.com/" target="blank" className="partner chicago-tribune">
              The Chicago Tribune
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.bostonglobe.com/" target="blank" className="partner boston-globe">
              The Boston Globe
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.seattletimes.com/" target="blank" className="partner seattle-times">
              The Seattle Times
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.palmbeachpost.com/" target="blank" className="partner palm-beach-post">
              The Palm Beach Post
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.miamiherald.com/" target="blank" className="partner miami-herald">
              The Miami Herald
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.sun-sentinel.com/" target="blank" className="partner sun-sentinel">
              The Sun Sentinel
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.wlrn.org/#stream/0" target="blank" className="partner wlrn">
              WLRN
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.orlandosentinel.com/" target="blank" className="partner orlando-sentinel">
              The Orlando Sentinel
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.tampabay.com/" target="blank" className="partner tampa-bay-times">
              Tampa Bay Times
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.nola.com/" target="blank" className="partner times-picayune">
              Times Picayune
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.theadvocate.com/" target="blank" className="partner advocate">
              The Advocate
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.app.com/" target="blank" className="partner asbury-park-press">
              Asbury Park Press
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.savannahnow.com/" target="blank" className="partner savannah-now">
              Savannah Now
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.climatecentral.org/" target="blank" className="partner climate-central">
              Climate Central
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://gothamist.com/" target="blank" className="partner gothamist">
              Gothamist
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://www.kqed.org/" target="blank" className="partner kqed">
              KQED
            </a>
          </Col>
          <Col sm={8}>
            <a href="https://maptian.com" target="blank" className="partner maptian">
              Maptian
            </a>
          </Col>
        </Row>
        </div>
     </div>

     </>
    )
  }
}

export default CCMap
